import facebookLogo from "./assets/images/Facebook_Logo.png";
import zaloLogo from "./assets/images/zaloLogo.png";
import logoInternet2 from "./assets/images/tuvanvien.jpg";
import hoaHong from "./assets/images/hoaHong.jpg";
import hoaCamTuCau from "./assets/images/hoaCamTuCau.jpg";
import gioHang from "./assets/images/giohang2.jpg";
import hoaBaby from "./assets/images/hoaBaby.jpg";
import hoaHueTay from "./assets/images/hoaHueTay.jpg";
import hoaTulip from "./assets/images/hoaTulip.png";
import hoaHuongDuong from "./assets/images/hoaHuongDuong.jpg";
import hoaSen from "./assets/images/hoaSen.jpeg";
import hoaLy from "./assets/images/hoaLy.jpg";
import hoaPhongLan from "./assets/images/hoaPhongLan.jpg";
import hoaHongTrang from "./assets/images/hoaHongTrang.jpg";
import hoaLanHoDiep from "./assets/images/hoaLanHoDiep.jpg";
import hoaAnhDao from "./assets/images/hoaAnhDao.jpg";
import hoaOaiHuong from "./assets/images/hoaOaiHuong.jpg";
import hoaCucHoaMi from "./assets/images/hoaCucHoaMi.jpg";
import hoaHongDen from "./assets/images/hoaHongDen.png";
import hoaLuoiHo from "./assets/images/hoaLuoiHo.png";

import "./App.scss";
import { useEffect, useState } from "react";
import { getTTShop } from "./ApiRequest";
import Loading from "./Loading";
import QRCode from "react-qr-code";
import logoInternet from "./assets/images/logoInternet.jpg";
function App() {
    const idShop = "65a759fa8236b1e3500d35d6";
    const [tuVanVaThongTin, settuVanVaThongTin] = useState(0);
    const [dataQrCode, setdataQrCode] = useState("");
    const [loading, setloading] = useState(1);
    // Thong Tin Shop
    const [ttShop, setttShop] = useState();
    useEffect(() => {
        const fetchPublicDictrict = async () => {
            const response = await getTTShop(idShop, setloading);
            if (response) {
                setttShop(response?.data.shop);
            }
        };
        idShop && fetchPublicDictrict();
    }, [idShop]);
    // console.log("ttShop", ttShop);
    // Thong Tin Shop
    const VND = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND",
    });
    const download = () => {
        const svg = document.getElementById("QRCode");
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL("image/png");
            const downloadLink = document.createElement("a");
            // name image
            downloadLink.download = `${dataQrCode}`;
            downloadLink.href = `${pngFile}`;
            downloadLink.click();
        };
        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    };
    // San Pham Dan
    const [spa, setspa] = useState();
    const [spb, setspb] = useState();
    const [sanPhamDan, setsanPhamDan] = useState([]);
    useEffect(() => {
        const limit = 2;
        const skip = 0;
        const fetchData = async () => {
            try {
                const res = await fetch(
                    `https://fabysa.com/v1/shop/san-pham-dan-huyen2/?huyen=${idShop}&skip=${skip}&limit=${limit}`
                );
                const data2 = await res.json();
                setsanPhamDan(data2?.allSanpham);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [idShop]);
    useEffect(() => {
        if (sanPhamDan && sanPhamDan?.length !== 0) {
            setspa(sanPhamDan[0]?._id);
            setspb(sanPhamDan[1]?._id);
        }
    }, [sanPhamDan]);
    // San Pham Dan
    // muaHang
    const handleMuaHang = () => {
        window.location.href = `https://fabysa.com/shop/${idShop}`;
    };
    // muaHang

    return (
        <>
            <div className="all">
                {(loading === 0 || loading !== 1) && (
                    <div className="App">
                        <div>
                            {/* Banner */}
                            <div className="ttShop">
                                <img
                                    src={ttShop?.Banner}
                                    className="banner-container"
                                />

                                <a href={`/`}>
                                    <div className="tenCuaHang">
                                        {ttShop?.TenShop}
                                    </div>

                                    <div className="internet-website">
                                        <img
                                            src={logoInternet}
                                            className="internet"
                                        />
                                        <div className="website">
                                            Https://
                                            {ttShop?.website}
                                        </div>
                                        <img
                                            src={logoInternet2}
                                            className="internet"
                                        />
                                    </div>
                                </a>
                            </div>
                            {/* Banner */}

                            {/* muaHang */}
                            <div
                                onClick={() => handleMuaHang()}
                                className="muaHang-container"
                            >
                                <img className="gioHang" src={gioHang} />
                                <div className="muaHang">Mua Hàng</div>
                            </div>
                            {/* muaHang */}

                            {/* Fabysa */}
                            <div className="tuVan-gioiThieu">
                                <div
                                    className="tuVan"
                                    onClick={() => settuVanVaThongTin(2)}
                                >
                                    <div>Fabysa</div>
                                </div>
                                <div
                                    className="gioiThieu"
                                    onClick={() => settuVanVaThongTin(1)}
                                >
                                    <div>Liên Hệ</div>
                                </div>
                            </div>
                            {/* Fabysa */}
                            {tuVanVaThongTin === 1 && (
                                <div className="gioiThieuChiTiet">
                                    <button
                                        className="closeGioiThieu"
                                        onClick={() => settuVanVaThongTin(0)}
                                    >
                                        Close
                                    </button>
                                    <div className="tenCuaHang2">
                                        {ttShop?.TenShop}
                                    </div>
                                    <div className="slogan">
                                        {ttShop?.sloganShop}
                                    </div>

                                    <div className="qrcode">
                                        <div
                                            onClick={download}
                                            style={{
                                                height: "auto",
                                                margin: "0 auto",
                                                maxWidth: 150,
                                                width: "100%",
                                            }}
                                        >
                                            <QRCode
                                                size={256}
                                                style={{
                                                    height: "auto",
                                                    maxWidth: "100%",
                                                    width: "100%",
                                                }}
                                                value={`https://${ttShop?.website}`}
                                                viewBox={`0 0 256 256`}
                                                id="QRCode"
                                            />
                                        </div>
                                    </div>

                                    <div className="dc">
                                        - Địa chỉ:&nbsp;
                                        {ttShop?.dcShop} <br />- Số điện
                                        thoại:&nbsp;
                                        {ttShop?.sdtShop} <br />- Quý khách cần
                                        hỗ trợ hoặc tư vấn xin vui lòng liên hệ
                                        trực tiếp 24/7 qua Zalo, Facebook!
                                    </div>

                                    <div className="mxh">
                                        <div className="zalo">
                                            <a
                                                href={`https://zalo.me/${ttShop?.linkZalo}`}
                                                target="_blank"
                                            >
                                                <img
                                                    src={zaloLogo}
                                                    className="zalo"
                                                />
                                            </a>
                                        </div>
                                        <div className="facebook">
                                            <a
                                                href={ttShop?.linkFacebook}
                                                target="_blank"
                                            >
                                                <img
                                                    src={facebookLogo}
                                                    className="facebook"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="camOn">
                                        Xin chân thành cảm ơn!
                                    </div>
                                    <a
                                        href={`https://fabysa.com/shop/dang-nhap/${idShop}`}
                                    >
                                        <div className="like">Thích</div>
                                    </a>
                                </div>
                            )}
                            {tuVanVaThongTin === 2 && (
                                <div className="tuVanChiTiet">
                                    <button
                                        className="closeGioiThieu"
                                        onClick={() => settuVanVaThongTin(0)}
                                    >
                                        Close
                                    </button>

                                    <div className="fabysa">
                                        Trung Tâm Thương Mại Fabysa
                                    </div>
                                    <div className="gioiThieuFabysa">
                                        - Giới thiệu danh sách Shop Uy Tín!
                                        <br /> - Sản phẩm đa dạng ngành hàng!{" "}
                                        <br />- Giá cả ưu đãi và rất nhiều
                                        khuyến mại!
                                    </div>
                                    <a
                                        href={`https://fabysa.com/fabysa/${spa}/${spb}`}
                                    >
                                        <div className="sanSale">
                                            Săn Sale Ngay
                                        </div>
                                    </a>
                                </div>
                            )}
                        </div>

                        {/* <div className="tieuDe">Ý Nghĩa Các Loài Hoa</div>
                        <div className="hoa">
                            <div className="loaiHoa">
                                <div className="tenLoaiHoa">1. Hoa hồng</div>
                                <img className="anhLoaiHoa" src={hoaHong} />
                                <div className="thongTinLoaiHoa">
                                    Hoa hồng được coi là biểu tượng của tình yêu
                                    và lãng mạn. Màu sắc của hoa hồng cũng có ý
                                    nghĩa riêng, chẳng hạn như hoa hồng đỏ thể
                                    hiện tình yêu chân thành và đam mê, hoa hồng
                                    trắng thể hiện tình yêu trong sạch và tôn
                                    trọng.
                                </div>
                            </div>
                            <div className="loaiHoa">
                                <div className="tenLoaiHoa">
                                    2. Hoa cẩm tú cầu
                                </div>
                                <img className="anhLoaiHoa" src={hoaCamTuCau} />
                                <div className="thongTinLoaiHoa">
                                    Hoa cẩm tú cầu thường được coi là biểu tượng
                                    của tình yêu vĩnh cửu và trung thành. Nó thể
                                    hiện tình yêu mạnh mẽ và sự đoàn kết.
                                </div>
                            </div>
                            <div className="loaiHoa">
                                <div className="tenLoaiHoa">
                                    3. Hoa baby’s breath
                                </div>
                                <img className="anhLoaiHoa" src={hoaBaby} />
                                <div className="thongTinLoaiHoa">
                                Hoa baby’s breath thường được sử dụng như là hoa phụ trang trí trong bó hoa. Nó thể hiện sự trong trắng và thuần khiết trong tình yêu.
                                </div>
                            </div>
                        </div> */}
                        <div className="tieuDe">Ý Nghĩa Các Loài Hoa</div>
                        <div className="hoa">
                            <div className="loaiHoa">
                                <div className="tenLoaiHoa">Hoa hồng</div>
                                <img className="anhLoaiHoa" src={hoaHong} />
                                <div className="thongTinLoaiHoa">
                                    Hoa hồng được coi là biểu tượng của tình yêu
                                    và lãng mạn. Màu sắc của hoa hồng cũng có ý
                                    nghĩa riêng, chẳng hạn như hoa hồng đỏ thể
                                    hiện tình yêu chân thành và đam mê, hoa hồng
                                    trắng thể hiện tình yêu trong sạch và tôn
                                    trọng.
                                </div>
                            </div>
                            <div className="loaiHoa">
                                <div className="tenLoaiHoa">Hoa cẩm tú cầu</div>
                                <img className="anhLoaiHoa" src={hoaCamTuCau} />
                                <div className="thongTinLoaiHoa">
                                    Hoa cẩm tú cầu thường được coi là biểu tượng
                                    của tình yêu vĩnh cửu và trung thành. Nó thể
                                    hiện tình yêu mạnh mẽ và sự đoàn kết.
                                </div>
                            </div>
                            <div className="loaiHoa">
                                <div className="tenLoaiHoa">
                                    Hoa baby’s breath (hoa ngọc lan tây)
                                </div>
                                <img className="anhLoaiHoa" src={hoaBaby} />
                                <div className="thongTinLoaiHoa">
                                    Hoa baby’s breath thường được sử dụng như là
                                    hoa phụ trang trí trong bó hoa. Nó thể hiện
                                    sự trong trắng và thuần khiết trong tình
                                    yêu.
                                </div>
                            </div>
                            <div className="loaiHoa">
                                <div className="tenLoaiHoa">Hoa huệ</div>
                                <img className="anhLoaiHoa" src={hoaHueTay} />
                                <div className="thongTinLoaiHoa">
                                    Hoa hoa huệ có nhiều màu sắc và mỗi màu có ý
                                    nghĩa khác nhau. Chẳng hạn, hoa huệ trắng
                                    thể hiện sự trong sáng và thuần khiết, hoa
                                    huệ hồng thể hiện sự ngọt ngào và lãng mạn.
                                </div>
                            </div>
                            <div className="loaiHoa">
                                <div className="tenLoaiHoa">Hoa tulip</div>
                                <img className="anhLoaiHoa" src={hoaTulip} />
                                <div className="thongTinLoaiHoa">
                                    Hoa tulip có ý nghĩa của tình yêu chân thành
                                    và lòng tin. Màu sắc của hoa tulip cũng mang
                                    ý nghĩa riêng, chẳng hạn như tulip đỏ thể
                                    hiện tình yêu đích thực, tulip vàng thể hiện
                                    tình yêu hạnh phúc và tulip trắng thể hiện
                                    tình yêu thuần khiết.
                                </div>
                            </div>
                            <div className="loaiHoa">
                                <div className="tenLoaiHoa">
                                    Hoa hướng dương
                                </div>
                                <img
                                    className="anhLoaiHoa"
                                    src={hoaHuongDuong}
                                />
                                <div className="thongTinLoaiHoa">
                                    Hoa hướng dương biểu trưng cho sự lạc quan,
                                    năng lượng và sự phát triển. Nó thể hiện
                                    tinh thần vươn lên, tự tin và sự tự hào.
                                </div>
                            </div>
                            <div className="loaiHoa">
                                <div className="tenLoaiHoa">Hoa sen</div>
                                <img className="anhLoaiHoa" src={hoaSen} />
                                <div className="thongTinLoaiHoa">
                                    Hoa sen mang ý nghĩa của sự thanh tịnh và
                                    tinh khiết. Nó biểu trưng cho sự trường thọ,
                                    sự trưởng thành và sự giác ngộ.
                                </div>
                            </div>
                            <div className="loaiHoa">
                                <div className="tenLoaiHoa">Hoa lily</div>
                                <img className="anhLoaiHoa" src={hoaLy} />
                                <div className="thongTinLoaiHoa">
                                    Hoa lily tượng trưng cho sự tinh khiết, vẻ
                                    đẹp và sự quý phái. Nó còn biểu thị sự trong
                                    trắng, sự tươi mới và sự tinh tế.
                                </div>
                            </div>
                            <div className="loaiHoa">
                                <div className="tenLoaiHoa">Hoa phong lan</div>
                                <img className="anhLoaiHoa" src={hoaPhongLan} />
                                <div className="thongTinLoaiHoa">
                                    Hoa phong lan biểu trưng cho sự quý phái, sự
                                    kiêu hãnh và sự tinh tế. Nó còn biểu thị sự
                                    mạnh mẽ và sự độc lập.
                                </div>
                            </div>
                            <div className="loaiHoa">
                                <div className="tenLoaiHoa">Hoa hồng trắng</div>
                                <img
                                    className="anhLoaiHoa"
                                    src={hoaHongTrang}
                                />
                                <div className="thongTinLoaiHoa">
                                    Hoa hồng trắng thể hiện sự trong sáng, sự
                                    thuần khiết và sự tôn trọng. Nó biểu trưng
                                    cho tình yêu chân thành và tình yêu vô điều
                                    kiện.
                                </div>
                            </div>
                            <div className="loaiHoa">
                                <div className="tenLoaiHoa">
                                    Hoa lan hồ điệp
                                </div>
                                <img
                                    className="anhLoaiHoa"
                                    src={hoaLanHoDiep}
                                />
                                <div className="thongTinLoaiHoa">
                                    Hoa lan hồ điệp mang ý nghĩa của sự quý
                                    phái, sự hiếm có và sự tinh tế. Nó thể hiện
                                    sự kiên nhẫn và sự kiên trì trong cuộc sống.
                                </div>
                            </div>
                            <div className="loaiHoa">
                                <div className="tenLoaiHoa">Hoa anh đào</div>
                                <img className="anhLoaiHoa" src={hoaAnhDao} />
                                <div className="thongTinLoaiHoa">
                                    Trong văn hóa Nhật Bản, hoa anh đào là biểu
                                    tượng của sự tinh khiết, sự đẹp đẽ và sự
                                    tưởng niệm. Khi hoa anh đào nở rộ, cảnh
                                    tượng tạo ra một không gian yên bình và mang
                                    đến sự cảm nhận về sự tạm giao và sự trân
                                    quý cuộc sống.
                                </div>
                            </div>
                            <div className="loaiHoa">
                                <div className="tenLoaiHoa">
                                    Hoa oải hương (Lavender)
                                </div>
                                <img className="anhLoaiHoa" src={hoaOaiHuong} />
                                <div className="thongTinLoaiHoa">
                                    Hoa oải hương thường được liên kết với sự
                                    thư thái, bình yên và tĩnh lặng. Mùi hương
                                    dịu nhẹ của oải hương có tác dụng thư giãn
                                    và giúp tạo ra không gian yên bình.
                                </div>
                            </div>
                            <div className="loaiHoa">
                                <div className="tenLoaiHoa">
                                    Hoa cúc hoạ mi
                                </div>
                                <img className="anhLoaiHoa" src={hoaCucHoaMi} />
                                <div className="thongTinLoaiHoa">
                                Hoa cúc họa mi là loài hoa tượng trưng cho tình yêu chân thành, thủy chung trước sau như một. Là loài hoa dại mỏng manh và dễ vỡ. Nhưng cúc họa mi là minh chứng cho tình yêu bền chặt và bất biến.
                                </div>
                            </div>
                            {/* <div className="loaiHoa">
                                <div className="tenLoaiHoa">
                                    Hoa hồng đen
                                </div>
                                <img className="anhLoaiHoa" src={hoaHongDen} />
                                <div className="thongTinLoaiHoa">
                                    Hoa hồng đen thường được coi là biểu tượng
                                    của sự chết, sự chia tay và sự buồn bã. Nó
                                    thường được sử dụng trong các dịp tang lễ và
                                    các bức tranh hoa tươi đại diện cho sự đau
                                    khổ và sự mất mát.
                                </div>
                            </div> */}
                            <div className="loaiHoa">
                                <div className="tenLoaiHoa">
                                    Hoa lưỡi hổ (Gladiolus)
                                </div>
                                <img className="anhLoaiHoa" src={hoaLuoiHo} />
                                <div className="thongTinLoaiHoa">
                                Theo quan niệm xưa, cây lưỡi hổ ra hoa là dấu hiệu cho thấy điềm lành sẽ tới với gia chủ. Trong năm đó cuộc sống của người trồng cây sẽ gặp nhiều may mắn. Bên cạnh đó, công việc cũng sẽ thuận lợi, sự nghiệp ổn định, tiền tài dồi dào, mọi sự đều được thuận buồm xuôi gió.
                                </div>
                            </div>
                            {/* <div className="loaiHoa">
                                <div className="tenLoaiHoa">Hoa lưỡi hổ (Gladiolus)</div>
                                <img className="anhLoaiHoa" src={hoaLuoiHo} />
                                <div className="thongTinLoaiHoa">
                                Hoa lưỡi hổ có một số loài có gai sắc nhọn trên cành, tượng trưng cho đau đớn và khó khăn. Nó cũng có thể đại diện cho sự hy sinh và sự chiến đấu trong cuộc sống.

                                </div>
                            </div> */}
                        </div>
                    </div>
                )}

                {loading === 1 && <Loading />}
                <div className="App2">
                    Ứng Dụng Được Tối Ưu Sử Dụng Trên Mobile
                </div>
            </div>
        </>
    );
}

export default App;
