import "./Loading.scss";

const Loading = () => {
    return (
        <div className="div">
            <span className="loader"></span>
            <div className="capNhatDuLieu">
                . . . Dữ Liệu Đang Cập Nhật . . .
            </div>
        </div>
    );
};
export default Loading;
