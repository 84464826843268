import axiosDefault from "axios";

export const getTTShop = async (idShop, setloading) => {
    try {
        const response = await axiosDefault.get(
            `https://fabysa.com/v1/shop/thong-tin-shop/?id=${idShop}`
        );
        setloading(0);
        return response;
    } catch (err) {
        console.log(err);
    }
};
